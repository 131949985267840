<template>
  <div class="space-y-4">
    <asom-card>
      <div class="py-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Station Fault Details
        </h3>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8">
          <asom-form-field label="Fault Number">
            <p class="font-semibold">
              {{ getObjectValue(itemData, "faultNo") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Notification ID/ Trag No / OCC No.">
            <p class="font-semibold">
              {{ getObjectValue(itemData, "notificationId") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Work Order Id">
            <p class="font-semibold">
              {{ getObjectValue(itemData, "workOrderId") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Work Order Status">
            <p class="font-semibold">
              {{ getObjectValue(itemData, "workOrderStatus") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Has maintenance staff been informed?">
            <p class="font-semibold">
              {{ get(itemData, "isMaintainanceStaffInformed") ? "YES" : "NO" }}
            </p>
          </asom-form-field>
          <asom-form-field label="Functional Location">
            <p class="font-semibold">
              {{
                getObjectValue(itemData, "momsNotification_FunctionalLocation")
              }}
            </p>
          </asom-form-field>
          <asom-form-field label="Planner Group">
            <p class="font-semibold">
              {{ getObjectValue(itemData, "momsNotification_PlannerGroup") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Description" class="col-span-2">
            <p class="font-semibold break-words">
              {{ getObjectValue(itemData, "description") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Remarks" class="col-span-2">
            <p class="font-semibold break-words">
              {{ getObjectValue(itemData, "remarks") }}
            </p>
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            v-if="itemData.files.length > 0"
            label="Supporting Document"
          >
            <asom-upload-file-list :files="itemData.files" disabled-remove />
          </asom-form-field>
        </div>
      </div>
      <station-fault-log-list 
        class="col-span-2" 
        :station-fault-id="stationFaultId"
      />
      <div class="col-span-2 flex justify-end space-x-4 pt-8">
        <asom-button text="Back" variant="secondary" @click="$router.go(-1)" />
        <asom-button
          v-if="canEditStationFault"
          size="sm"
          outline
          text="Edit"
          @click="navigateToUpdate"
        />
      </div>
    </asom-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import { getStationFaultDetails } from "../../../services/faultManagement.service";
import { getObjectValue } from "../../../helpers/getObjectValue";
import StationFaultLogList from "./StationFaultLogList.vue"

export default {
  name: "ViewOrder",
  components: {
    StationFaultLogList
  },
  data() {
    return {
      selectedItem: null,
      itemData: {
        faultNo: get(this.$route.params, "faultNo", null),
        description: get(this.$route.params, "description", null),
        remarks: get(this.$route.params, "remarks", null),
        tragNo: get(this.$route.params, "tragNo", null),
        isMaintainanceStaffInformed: get(
          this.$route.params,
          "isMaintainanceStaffInformed",
          null
        ),
        workOrderId: get(this.$route.params, "workOrderId", null),
        workOrderStatus: get(this.$route.params, "workOrderStatus", null),
        notificationId: get(this.$route.params, "notificationId", null),
        momsNotification_FunctionalLocation: get(
          this.$route.params,
          "momsNotification_FunctionalLocation",
          null
        ),
        momsNotification_PlannerGroup: get(
          this.$route.params,
          "momsNotification_PlannerGroup",
          null
        ),
        files: [],
      },
    };
  },
  mounted() {
    this.getFaultDetails();
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      canEditStationFault: "auth/canEditStationFault",
    }),
    stationFaultId() {
      return get(this.$route, 'params.faultId')
    }
  },
  methods: {
    get,
    getObjectValue,
    navigateToUpdate() {
      this.$router.push({
        name: "Edit Station Fault",
        params: this.selectedItem,
      });
    },
    async getFaultDetails() {
      const result = await getStationFaultDetails({
        faultId: get(this.$route.params, "faultId"),
        stationId: this.stationId,
      });
      if (result.success) {
        this.selectedItem = get(result.payload, "model");
        this.setFormData(get(result.payload, "model"));
      }
    },
    setFormData(data) {
      this.itemData.faultNo = get(data, "faultNo", "-");
      this.itemData.description = get(data, "description", "-");
      this.itemData.remarks = get(data, "remarks", "-");
      this.itemData.tragNo = get(data, "tragNo", "-");
      this.itemData.isMaintainanceStaffInformed = get(
        data,
        "isMaintainanceStaffInformed",
        "-"
      );
      this.itemData.momsNotification_FunctionalLocation = get(
        data,
        "momsNotification_FunctionalLocation",
        "-"
      );
      this.itemData.momsNotification_PlannerGroup = get(
        data,
        "momsNotification_PlannerGroup",
        "-"
      );
      this.itemData.notificationId = get(
        data,
        "notificationId",
        "-"
      );
      this.itemData.workOrderId = get(data, "workOrderId", "-");
      this.itemData.workOrderStatus = get(
        data,
        "workOrderStatus",
        "-"
      );
      let attachments = get(data, "attachments", null);
      if (attachments.length > 0) this.itemData.files = attachments;
    },
  },
};
</script>
