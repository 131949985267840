<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            required
            :state="inputStates('formData.branch')"
            error="Branch is required"
            label="Branch"
            description="Branch value for MOMS Notification creation"
          >
            <asom-input-select
              :state="inputStates('formData.branch')"
              v-model="formData.branch"
              :options="branchOptions"
            />
          </asom-form-field>
          <div></div>
          <asom-form-field
            required
            label="Description"
            :state="inputStates('formData.description')"
            error="Description is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.description"
              :state="inputStates('formData.description')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field
            :state="inputStates('formData.isMaintainanceStaffInformed')"
            error="Please Inform Maintainance Staff"
            label=" "
          >
            <asom-input-checkbox
              v-model="formData.isMaintainanceStaffInformed"
              label="Is Maintainance Staff Informed"
              description="Check if Maintainance Staff is Informed"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            label="Supporting documents"
            :state="inputStates('formData.files')"
            error="Supporting documents are required"
          >
            <asom-upload
              v-model="formData.files"
              :category="attachmentCategories.FAULT_MANAGEMENT"
              :state="inputStates('formData.files')"
            />
          </asom-form-field>
          <div class="col-span-2 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Back"
              variant="secondary"
              @click="$router.go(-1)"
            />
            <asom-button
              text="Create"
              @click="createStationFaultClick"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { createStationFault, GetMOMSBranches,
  GetAllocationworkcentreplannergroup
 } from "@/services/faultManagement.service";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: false,
      formData: {
        description: null,
        isMaintainanceStaffInformed: false,
        files: [],
        branch: null,
      },
      branchOptions: [],
      lannergroup: {
        functionalLocation: '',
        workCentre: '',
        plannerGroup: ''
      },
      abbreviationName: ''
    };
  },
  validations() {
    return {
      formData: {
        description: { required },
        branch: { required },
      },
    };
  },
  mounted() {
    this.loadBranches();
  },
  watch: {
    'formData.branch': function(newVal) {
      if (!isEmpty(newVal)) {
        this.loadWorkCenters()
      }
    }
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      userLineId: "auth/userLineId",
      userLineName: "auth/userLineName"
    }),
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  methods: {
    get,
    createStationFaultClick() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async loadBranches() {
      const stationOptionsList = this.$store.getters["smrtResource/stationOptions"];
      const selectedStationId = this.$store.getters["selectedStation/id"];
      const itemAbbreviation = stationOptionsList.find(({ value }) => value == selectedStationId);
      if(itemAbbreviation.label) {
        const abbreviationmore = itemAbbreviation.label?.split('(')[1];
        const abbreviation = abbreviationmore.slice(0, -1);
        this.abbreviationName = abbreviation
      }

      const result = await GetMOMSBranches({
        lineId: this.$store.getters["selectedStation/lineId"],
        StationAbbreviation: this.abbreviationName,
      })
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.branchOptions = get(result, "payload.list", []).map(item => {
          return { label: item.branchName, value: item.branchName }
        });
      } else {
        this.error = result.payload;
      }
    },
    async loadWorkCenters() {
      const result = await GetAllocationworkcentreplannergroup({
        lineId: this.$store.getters["selectedStation/lineId"], //切换的线
        BranchName: this.formData.branch.value,
        StationAbbreviation: this.abbreviationName, //切换的站
      })
      if (result.success) this.lannergroup = result.payload.data;
    },
    async onSubmit() {
      let uploadedIds = [];
      if (this.formData.files) {
        uploadedIds = this.formData.files.map((file) => file.fileId);
      }
      this.isSubmitting = true;
      const prams = {
        lineId: this.userLineId,
        stationId: this.stationId,
        description: this.formData.description,
        isMaintainanceStaffInformed: this.formData.isMaintainanceStaffInformed,
        attachments: uploadedIds,
        functionalLocation: this.lannergroup.functionalLocation,
        plannerGroup: this.lannergroup.plannerGroup,
        workCenter: this.lannergroup.workCentre,
        planningPlant: this.lannergroup.planningPlant
      }
      console.log(prams);
      const result = await createStationFault(prams);
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
