import {
  CREATE_FAULT_OCCURRENCE,
  FAULT_OCCURRENCE_DETAILS,
  FAULT_OCCURRENCE_LIST,
  CREATE_STATION_FAULT,
  STATION_FAULT_DETAILS,
  STATION_FAULT_LIST,
  CREATE_FAULT_EBOOKLET,
  DELETE_FAULT_EBOOKLET,
  EDIT_FAULT_EBOOKLET,
  FAULT_EBOOKLET_DETAILS,
  FAULT_EBOOKLET_LIST,
  EDIT_FAULT_OCCURRENCE,
  EDIT_STATION_FAULT,
  FAULT_MANAGEMENT_HEALTH_CHECK,
  FAULT_MANAGEMENT_CONFIGURATION_DETAILS,
  FAULT_OCCURRENCE_UPDATE_THRESHOLD_EXPIRE,
  FAULT_OCCURRENCE_TRANSACTION_DETAILS,
  STATION_FAULT_TRANSACTION_DETAILS,
  STATION_FAULT_LOG_LIST,
  LIST_MOMS_BRANCH,
  LIST_MOMS_GROUP,
} from "../constants/apis";
import createAxiosInstance from "./createAxiosInstance";
import { getURLSearchParams } from "./service.helpers";
import { successHandler, errorHandler } from "./serviceHandlers";

export async function faultManagementHealthCheck() {
  try {
    const response = await createAxiosInstance(true).get(
      FAULT_MANAGEMENT_HEALTH_CHECK
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getFaultManagementConfigurationDetails(lineId) {
  try {
    const response = await createAxiosInstance(true).get(
      FAULT_MANAGEMENT_CONFIGURATION_DETAILS,
      {
        params: { lineId },
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function stationOccurrencesUpdateThresholdExpiration(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      FAULT_OCCURRENCE_UPDATE_THRESHOLD_EXPIRE,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createFaultOccurrence(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_FAULT_OCCURRENCE,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editFaultOccurrence(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_FAULT_OCCURRENCE,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getFaultOccurrenceDetails({
  faultOccurrenceId,
  stationId,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        faultOccurrenceId,
        stationId,
      },
    });
    const response = await createAxiosInstance(true).get(
      FAULT_OCCURRENCE_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getStationFaultTransactionDetails({ transactionId }) {
  try {
    const params = getURLSearchParams({
      data: {
        transactionId,
      },
    });
    const response = await createAxiosInstance(true).get(
      STATION_FAULT_TRANSACTION_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getListOfFaultOccurrence({
  skip = 0,
  take = 100,
  search,
  lineId,
  stationId,
  searchDateFrom,
  searchDateTo,
  isItemOpen,
  isItemClosed,
  isItemOutStanding,
  isActionRequired,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        skip,
        take,
        search,
        lineId,
        stationId,
        searchDateFrom,
        searchDateTo,
        isItemOpen,
        isItemClosed,
        isItemOutStanding,
        isActionRequired,
      },
    });
    const response = await createAxiosInstance(true).get(
      FAULT_OCCURRENCE_LIST,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createStationFault(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_STATION_FAULT,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editStationFault(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_STATION_FAULT,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getFaultOccurrenceTransactionDetails({ transactionId }) {
  try {
    const params = getURLSearchParams({
      data: {
        transactionId,
      },
    });
    const response = await createAxiosInstance(true).get(
      FAULT_OCCURRENCE_TRANSACTION_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getStationFaultDetails({ faultId, stationId }) {
  try {
    const params = getURLSearchParams({
      data: {
        faultId,
        stationId,
      },
    });
    const response = await createAxiosInstance(true).get(
      STATION_FAULT_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getListOfStationFault({
  skip = 0,
  take = 100,
  search,
  lineId,
  stationId,
  searchDateFrom,
  searchDateTo,
  includeOpenedWorkOrder,
  includeClosedWorkOrder,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        skip,
        take,
        search,
        lineId,
        stationId,
        searchDateFrom,
        searchDateTo,
        includeOpenedWorkOrder,
        includeClosedWorkOrder,
      },
    });
    const response = await createAxiosInstance(true).get(STATION_FAULT_LIST, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createFaultEBooklet(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_FAULT_EBOOKLET,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editFaultEBooklet(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_FAULT_EBOOKLET,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function deleteFaultEBooklet(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      DELETE_FAULT_EBOOKLET,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getFaultEBookletDetails({ faultEBookletId }) {
  try {
    const params = getURLSearchParams({
      data: {
        faultEBookletId,
      },
    });
    const response = await createAxiosInstance(true).get(
      FAULT_EBOOKLET_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getListOfFaultEBooklets({
  skip = 0,
  take = 100,
  search,
  lineId
}) {
  try {
    const params = getURLSearchParams({
      data: {
        skip,
        take,
        search,
        lineId
      },
    });
    const response = await createAxiosInstance(true).get(FAULT_EBOOKLET_LIST, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function GetMOMSBranches({
  lineId,
  StationAbbreviation
}) {
  try {
    const params = getURLSearchParams({
      data: {
        lineId,
        StationAbbreviation
      },
    });
    const response = await createAxiosInstance(true).get(
      LIST_MOMS_BRANCH,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function GetAllocationworkcentreplannergroup({
  lineId,
  BranchName,
  StationAbbreviation
}) {
  try {
    const params = getURLSearchParams({
      data: {
        lineId,
        BranchName,
        StationAbbreviation
      },
    });
    const response = await createAxiosInstance(true).get(
      LIST_MOMS_GROUP,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getListOfStationFaultLog({
  skip = 0,
  take = 10,
  stationFaultId
}) {
  try {
    const params = getURLSearchParams({
      data: {
        skip,
        take,
        stationFaultId,
      },
    });
    const response = await createAxiosInstance(true).get(STATION_FAULT_LOG_LIST, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}