import axios from "axios";
import get from 'lodash.get'
import { sanitizeUrl } from '@braintree/sanitize-url'
import { v4 as uuidv4 } from 'uuid';

import store from "@/store";

// interceptor
axios.interceptors.request.use(function(config) {
  config.url = sanitizeUrl(config.url);
  return config;
})

export default (secured = true) => {
  let deviceId = window.localStorage.getItem("asomsDeviceId");
  if(!deviceId) {
    // generate a unique id and store it
    deviceId = uuidv4();
    window.localStorage.setItem("asomsDeviceId", deviceId);
  } 
  if (secured) {
    return axios.create({
      headers: { 
        "Authorization": `bearer ${get(store, 'state.auth.token')}`,
        "x-asoms-device-id": deviceId
      }
    });
  } else {
    return axios.create({
      headers: {
        "x-asoms-device-id": deviceId
      }
    });
  }
} 